<template>
    <div
        class="container content-width ipad"
        :class="{ 'step-en': $i18n.locale === 'en', 'step-ja': $i18n.locale === 'ja' }"
    >
        <div class="block"></div>

        <div class="ts-measure ts-measure-center">
            <h1 class="page-title no-upercase">{{ $t("title") }}</h1>
        </div>

        <div id="HowToInstall" class="anchor"></div>

        <div class="ts-measure">
            <h2>{{ $t("install.title") }}</h2>
            <p class="ts-lead ts-quiet">{{ $t("install.text") }}</p>
        </div>

        <div class="block-md"></div>

        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">01</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step01.title')"></h3>
                    <p v-html="$t('install.step01.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-01">
                    <img width="400" :src="imgSrc('01.png')" :alt="$t('install.step01.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">02</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step02.title')"></h3>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-02">
                    <img width="400" :src="imgSrc('02.png')" :alt="$t('install.step02.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">03</h3>
                    <img height="34" src="@/assets/images/qr-code-scan.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step03.title')"></h3>
                    <p class="ts-underline ts-bold" v-html="$t('install.step03.note')"></p>
                    <p v-html="$t('install.step03.text1')"></p>
                    <p v-html="$t('install.step03.text2')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-03">
                    <img width="400" :src="imgSrc('03.png')" :alt="$t('install.step03.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">04</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step04.title')"></h3>
                    <p v-html="$t('install.step04.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-04">
                    <img width="400" :src="imgSrc('04.png')" :alt="$t('install.step04.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">05</h3>
                    <img height="34" src="@/assets/images/form-icon.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step05.title')"></h3>
                    <p v-html="$t('install.step05.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-05">
                    <img width="400" :src="imgSrc('05.png')" :alt="$t('install.step05.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">06</h3>
                    <img height="34" src="@/assets/images/form-icon.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step06.title')"></h3>
                    <p v-html="$t('install.step06.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-09">
                    <img width="400" :src="imgSrc('09.png')" :alt="$t('install.step06.title')" />
                </div>
            </div>
        </div>

        <div id="HowToUse" class="anchor"></div>

        <div class="ts-measure-tight">
            <h2 v-html="$t('use.title')"></h2>
            <p class="ts-lead ts-quiet" v-html="$t('use.text')"></p>
        </div>

        <div class="block-md"></div>

        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-body">
                    <p v-html="$t('use.step01.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-06">
                    <img width="400" :src="imgSrc('06.png')" :alt="$t('use.step01.title')" />
                </div>
            </div>
        </div>

        <div id="APN" class="grid grid-50 grid-list vertical-align-start no-border mb-3">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">{{ $t("use.step02.tag") }}</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('use.step02.title')"></h3>
                    <p v-html="$t('use.step02.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-07 mb-3">
                    <img width="400" :src="imgSrc('07.png')" :alt="$t('use.step02.title')" />
                </div>
                <div class="step-img step-img-08 mb-3">
                    <img width="400" :src="imgSrc('08.png')" :alt="$t('use.step02.title')" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.set_up_ipad.title"),
            description: this.$i18n.t("meta.set_up_ipad.description"),
        })
    },

    data() {
        return {
            imageToggle: "balance",

            openImage: false,
        }
    },
    methods: {
        imgSrc(fileName, os = "13.1.3") {
            const locale = this.$i18n.locale
            return require(`@/assets/images/screenshot/set-up-ipad/${os}/${locale}/${fileName}`)
        },
    },
    i18n: {
        messages: {
            en: {
                title: "SET UP GUIDE FOR iPad",
                install: {
                    title: "How To Install Stork Mobile eSIM",
                    text:
                        "The instruction below is for iPad (7th generation WiFi+Cellular model) running iPadOS 13. The actual texts or screen might be different depending on the OS version and the device.\n",
                    step01: {
                        title:
                            "Tap <span class='ts-italic'>Cellular Data</span> > <span class='ts-italic'>Add a New Plan</span> from Settings",
                        text: "Make sure your device is connected to a stable WiFi network.",
                    },
                    step02: {
                        title:
                            "Select <span class='ts-italic'>“Other …”</span> at the bottom of the carrier list",
                    },
                    step03: {
                        title: "Scan QR code we provide",
                        note: "* Internet connection required",
                        text1:
                            "In case you have only one device and cannot scan the QR code, enter the activation code and SM-DP+ address manually.",
                        text2: "They are provided along with the QR code via email.",
                    },
                    step04: {
                        title: "Tap <span class='ts-italic'>Add Cellular Plan</span>",
                        text: "Note that you cannot go back or cancel after you tap it.",
                    },
                    step05: {
                        title: "Make sure it’s added to Cellular Plans",
                        text:
                            "Some iPadOS versions force to change the plan name from Stork Mobile to Carrier or Carrier-Carrier but it does not affect the eSIM functions.",
                    },
                    step06: {
                        title:
                            "Tap <span class='ts-italic'>Update</span> on Carrier Settings Update",
                        text:
                            "APN (Access Point Name), which is required for Personal Hotspot, will be configured automatically by doing this step.",
                    },
                },
                use: {
                    title: "How To Use Stork Mobile eSIM For Mobile Data",
                    text: "Follow these steps after you arrive at your travel destination.",
                    step01: {
                        text:
                            "Make sure Roaming is turned on. Select and activate the Stork Mobile eSIM under Cellular Plans.",
                    },
                    step02: {
                        tag: "OPTIONAL",
                        title: "For Personal Hotspot, set up APN (Access Point Name)",
                        text:
                            "In case you did not update the Carrier Settings (Step 06 above) and want to use Personal Hotspot feature, tap Cellular Data Network and enter “stork” in all 4 APN fields (Cellular Data, LTE setup, MMS and Personal Hotspot)",
                    },
                },
                amp: {
                    next: {
                        title: "about stork mobile",
                    },
                },
            },
            ja: {
                title: "SET UP GUIDE FOR iPad",
                install: {
                    title: "eSIM のインストール方法",
                    text:
                        "ここでは第7世代 iPad (iOS 13) での設定方法を解説します。iOSのバージョンや機種によって使われるテキストなどが多少異なる場合があります。",
                    step01: {
                        title: "設定アプリのモバイルデータ通信から「新規プランを追加」をタップ",
                        text: "安定したWiFiに接続されていることをご確認ください。",
                    },
                    step02: {
                        title: "通信事業者のリストの最後にある「その他…」を選択",
                    },
                    step03: {
                        title: "QR コードを読み取る",
                        note: "* インターネットが必要です",
                        text1:
                            "もし端末が一台しかなくQRコードをスキャンできない場合は、SM-DP+ アドレスとアクティベーションコードを手動で入力してください。",
                        text2: "これらの情報はQRコードと合わせてメールでお送りします。",
                    },
                    step04: {
                        title: "「モバイル通信プランを追加」をタップ",
                        text: "タップ後はキャンセルやQRコードの再利用はできません。",
                    },
                    step05: {
                        title: "モバイル通信プランに追加されていることを確認",
                        text:
                            "iPadのOSによっては名称がStork Mobileから有効やCarrierに自動的に変更されてしまいますが、機能に影響はありません。",
                    },
                    step06: {
                        title: "キャリア設定アップデートのダイアログで「アップデート」を選択",
                        text:
                            "これによりインターネット共有を使う際に必要なAPN（アクセスポイント名）の設定を自動で行うことができます。",
                    },
                },
                use: {
                    title: "eSIMをデータ通信に使う",
                    text: "旅行先に到着したら、次の手順にしたがって設定してください。",
                    step01: {
                        title: "Stork Mobile eSIM を有効にする",
                        text:
                            "通信のオプションでデータローミングをオンに設定し、モバイル通信プランから追加されたeSIMが選択してください。",
                    },
                    step02: {
                        tag: "任意",
                        title: "インターネット共有利用の場合はAPNを設定",
                        text:
                            "キャリア設定アップデート（上記ステップ06）を行っていない端末でインターネット共有を使う場合は、「モバイルデータ通信ネットワーク」をタップし、4つのAPNフィールド（モバイルデータ通信、LTE設定、MMS、インターネット共有）に「stork」を入力してください。",
                    },
                },
                amp: {
                    next: {
                        title: "stork mobileについて",
                    },
                },
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.image-wrapper {
    width: 415px;
    max-height: 300px;

    @media (max-width: 415px) {
        width: 100%;
    }
    overflow: hidden;
    position: relative;
    &:after {
        content: " ";
        width: 413px;
        height: 298px;
        background: linear-gradient(rgba(255, 255, 255, 0.5), #fff);
        position: absolute;
        top: 1px;
        left: 1px;
    }
    button {
        z-index: 9999;
        position: absolute;
        top: 150px;
        left: 30%;
        right: 30%;
        @media (max-width: 400px) {
            left: 28%;
            right: 28%;
        }
    }
    &.open {
        max-height: none;
        overflow: auto;
        &:after {
            display: none;
        }
        button {
            display: none;
        }
    }
}
</style>
